@import "shared/styles/media";
@import "shared/styles/typography.scss";

.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  text-align: center;

  &.show_logo {
    .title {
      position: absolute;
      z-index: 1;
      bottom: -10px;
    }
  }

  .title {
    @extend %section_title_bottom_line;
  }

  .logo {
    max-width: 696px;
    width: 100%;
    height: auto;
    color: #f1f0f5;
  }

  @include md {
    &.show_logo {
      .title {
        position: relative;
        bottom: initial;
        bottom: 40px;
      }
    }
  }
}
