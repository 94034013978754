@import "shared/styles/typography.scss";

.checkbox {
  display: inline-flex;
  align-items: center;
  gap: 5px;

  cursor: pointer;

  &:hover {
    .check:not(.disabled, .checked) {
      border-color: var(--black);
    }
  }
}

.disabled {
}

.box {
  padding: 3px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  @extend %paragraph_16_med;
}

.check {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px solid var(--medium-gray);
  border-radius: 3px;

  input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .icon {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: border-color 0.15s ease;
    transform: rotate(0.000001deg);
    margin: 0;
    color: transparent;
    padding: 1px;

    svg {
      width: 100%;
      height: 100%;
      flex-shrink: 0;
    }
  }

  &:not(.disabled, .checked):hover {
    border-color: var(--black);
  }

  &.checked {
    background: var(--accent);
    border-color: var(--accent);
  }

  &.checked .icon {
    color: var(--white);
  }

  &.checked + .icon {
    svg {
      opacity: 0;
    }
  }

  .disabled.checked .icon {
    color: var(--medium-gray);
  }
}
